// bootstrap palette

$primary: rgb(0, 183, 190);
$text-primary: white;

$info: rgb(48, 45, 108);

@import '~bootstrap/scss/bootstrap';
@import 'variables.scss';
@import '~swiper/swiper-bundle';
@import '~@videogular/ngx-videogular/fonts/videogular.css';
@import '~@ng-select/ng-select/themes/default.theme.css';

@import '~quill/dist/quill.core.css';
@import '~quill/dist/quill.bubble.css';
@import '~quill/dist/quill.snow.css';

// overrides

.btn-primary {
  color: white !important;
}

body {
  font-family: 'Exo';
  margin: 0px;
  padding: 0px;
  width: 100vw;
  height: 100vh;
  max-height: -webkit-fill-available;
  min-width: 300px;
}

.link-unstyled {
  text-decoration: none;
  color: black;
}

.header-title {
  background-color: #17305a;
  width: fit-content;
  font-weight: bolder;
  color: $title-yellow;
  padding-right: 20px;
  padding-left: 20px;

  .lighter {
    font-weight: 100;
  }
}

.rounded-input {
  border-radius: 1rem;
}

.button-spinner {
  margin-left: 5px;
  height: 1rem;
  width: 1rem;
}

.main-container {
  width: 100%;
  height: 100%;
}

.hint {
  color: rgb(133, 133, 133);
}

.cursor-pointer {
  cursor: pointer;
}

.info-tab-content {
  display: flex;
  flex: 0 0 100%;
  flex-direction: row;
  width: 100%;
  height: 100%;
  max-height: 100%;
  overflow-y: auto;
  position: relative;

  @include media-breakpoint-up(lg) {
    .inner {
      padding: 20px;
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
    }

    .left {
      display: flex;
      flex: 0 0 30%;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      img {
        max-height: 300px;
      }
    }

    .right {
      display: flex;
      flex: 0 0 70%;
      padding-left: 30px;
      padding-right: 20px;
      flex-direction: column;
      justify-content: center;
      font-size: 1.2rem;
      text-align: justify;
      max-height: 100%;
      overflow-y: auto;
      position: relative;
      overflow-wrap: normal;

      .right-inner {
        padding: 20px 50px 50px 20px;
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
      }
    }
  }

  @include media-breakpoint-down(lg) {
    flex-direction: column;

    padding: 10px;

    .left {
      display: flex;
      justify-content: center;
      width: 100%;
      max-height: 150px;

      img {
        display: flex;
        max-width: 60%;
        max-height: 100%;
        object-fit: contain;
      }
    }
  }
}

a {
  color: #333366;
}

.contained-image {
  display: flex;
  max-width: 100%;
  max-height: 100%;
}
